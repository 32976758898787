<template>
  <v-card class="px-3 py-5">
    <v-row>
      <v-col
        cols="12"
        md="auto">
        <h2>In Transit Report</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="auto"
        order="last"
        order-md="0">
        <v-tabs
          v-model="selectedTab"
          show-arrows
          color="secondary">
          <v-tab
            v-for="(tabTitle, index) in tabs"
            :key="`tabTitle-${index}`">
            <span class="mr-1">
              {{ tabTitle.label }} |
            </span>
            <span
              v-if="loadingCount"
              class="count-tab">
              <v-progress-circular
                indeterminate
                size="16" />
            </span>
            <span v-else>
              {{ tabTitle.count }}
            </span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <WarehouseSelect
          v-model="query.warehouse"
          :items="warehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          outlined />
      </v-col>
      <v-col
        cols="12"
        md="auto">
        <search-box
          v-model="query.search"
          @on-search="getInTransitReport(true)" />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="query.limit"
      :loading="loading"
      class="elevation-1 mt-3">
      <template #[`item.origin`]="{ item }">
        <div
          v-if="selectedTab === 1"
          class="d-flex flex-column justify-center align-center">
          <span>
            {{ item.warehouse.name }}
          </span>
          <a
            :href="`/notes/withdraw-note?id=${item.id}&warehouse=${item.warehouse.id}`"
            target="_blank">
            ({{ item.code }})
          </a>
        </div>
        <div
          v-else
          class="d-flex flex-column justify-center align-center">
          <span>
            {{ item.reference.warehouse.name }}
          </span>
          <span>
            ({{ item.reference.code }})
          </span>
        </div>
      </template>
      <template #[`item.reference`]="{ item }">
        <div
          v-if="selectedTab === 1"
          class="d-flex flex-column justify-center align-center">
          <span>
            {{ item.reference.warehouse.name }}
          </span>
          <span>
            ({{ item.reference.code }})
          </span>
        </div>
        <div
          v-else
          class="d-flex flex-column justify-center align-center">
          <span>
            {{ item.warehouse.name }}
          </span>
          <a
            :href="`/notes/delivery-note?id=${item.id}&warehouse=${item.warehouse.id}`"
            target="_blank">
            ({{ item.code }})
          </a>
        </div>
      </template>
      <template #[`item.updatedAt`]="{ item }">
        <span>
          {{ item.updatedAt | dateTimeUserFormat() }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import InventoryProvider from '@/resources/InventoryProvider'

const InventoryService = new InventoryProvider()

export default {
  components: {
    SearchBox,
    WarehouseSelect
  },
  data () {
    return {
      loading: false,
      loadingCount: false,
      headers: [
        {
          text: 'ต้นทาง',
          value: 'origin',
          align: 'center',
          sortable: false
        },
        {
          text: 'ปลายทาง',
          value: 'reference',
          align: 'center',
          sortable: false
        },
        {
          text: 'เวลาส่งออก',
          value: 'updatedAt',
          align: 'center',
          sortable: false
        },
        {
          text: 'หมายเหตุ',
          value: 'note',
          sortable: false
        }
      ],
      tabs: [
        {
          label: 'ขาเข้า',
          value: 'deliveryNotes',
          count: 0
        },
        {
          label: 'ขาออก',
          value: 'withdrawNotes',
          count: 0
        }
      ],
      selectedTab: 0,
      query: {
        page: 1,
        limit: 15,
        search: '',
        warehouse: null
      },
      totalPage: 1,
      items: []
    }
  },
  computed: {
    mapWarehouse () {
      return this.$store.getters['Store/mapWarehouse']
    },
    warehouses () {
      return this.mapWarehouse.filter((v) => v.id !== 0)
    }
  },
  watch: {
    'query.warehouse': {
      handler () {
        this.getInTransitReport(true)
      },
      deep: true
    },
    warehouses (val) {
      if (!this.query.warehouse && val.length >= 1) {
        this.query.warehouse = this.warehouses[0].id
      }
    },
    selectedTab () {
      this.getInTransitReport(true)
    }
  },
  mounted () {
    if (!this.query.warehouse && this.warehouses.length >= 1) {
      this.query.warehouse = this.warehouses[0].id
    }
  },
  methods: {
    async fetchCount () {
      try {
        this.loadingCount = true

        const { data: deliveryNotes } = await InventoryService.getInTransitDeliveryCount(this.query)
        const { data: withdrawNotes } = await InventoryService.getInTransitWithdrawCount(this.query)

        const data = { deliveryNotes, withdrawNotes }

        for (let i = 0; i < this.tabs.length; i++) {
          this.tabs[i].count = data[this.tabs[i].value]
        }
      } catch (error) {
        console.error('fetchCount', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loadingCount = false
      }
    },
    async getInTransitReport (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []

          this.fetchCount()
        }

        if (this.selectedTab === 0) {
          const { data } = await InventoryService.getInTransitDelivery(this.query)

          this.items = data
        } else if (this.selectedTab === 1) {
          const { data } = await InventoryService.getInTransitWithdraw(this.query)

          this.items = data
        }
      } catch (error) {
        console.error('getInTransitReport', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
